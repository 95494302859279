export const locales = [
	{
		id: "pl",
		slug: "pl",
		iso: "pl-PL",
		default: true,
		name: {
			pl: "Polski",
			en: "Polish",
		},
		dateTimeFormat: {
			date: "dd.MM.yyyy",
			time: "HH:mm",
			dateTime: "dd.MM.yyyy HH:mm",
		},
	},
	{
		id: "en",
		slug: "en",
		iso: "en-US",
		default: false,
		name: {
			pl: "Angielski",
			en: "English",
		},
		dateTimeFormat: {
			date: "MM/dd/yyyy",
			time: "hh:mm a",
			dateTime: "MM/dd/yyyy hh:mm a",
		},
	},
];
